/* eslint-disable react/no-danger */
import React, { ReactElement } from 'react';

import { MenuItem, SubMenu } from '@sede-x/shell-ds-react-framework';

import { ISideNavMenu } from './type';

const handleCMTOnClick = () => {
  window.open(process.env.REACT_APP_CMT_WEB_APP_URL, '_blank');
};

const handleGenesisStandaloneOnClick = () => {
  window.open(process.env.REACT_APP_GENESIS_WEB_APP_URL, '_blank');
};

export const getSubMenuList = (
  subMenu: ISideNavMenu[],
  getMenuClassName: (menupath: string) => string
) => {
  const subMenuList: ReactElement[] = subMenu
    .filter((item) => item !== null)
    .sort(
      (a, b) =>
        (a.menuOrder ?? Number.MAX_SAFE_INTEGER) -
        (b.menuOrder ?? Number.MAX_SAFE_INTEGER)
    )
    .map((item) => {
      if (Array.isArray(item.subMenu)) {
        return (
          <SubMenu title={`${item.title}`} key={`${item.id}_sm`}>
            {getSubMenuList(item.subMenu, getMenuClassName)}
          </SubMenu>
        );
      }
      return (
        <MenuItem
          key={item.path ?? ''}
          className={getMenuClassName(item.path ?? '')}
          style={{ whiteSpace: 'break-spaces' }}
        >
          {item.title}
        </MenuItem>
      );
    });

  return subMenuList;
};

export const getMenuItem = (
  item: ISideNavMenu,
  getMenuClassName: (menupath: string) => string
) => {
  if (Array.isArray(item.subMenu)) {
    return (
      <SubMenu
        title={`${item.title}`}
        key={`${item.id}_sm`}
        className='breakOnSpaces'
      >
        {getSubMenuList(item.subMenu, getMenuClassName)}
      </SubMenu>
    );
  }
  if (item.title === 'CMT') {
    // Check for "CMT" item
    return (
      <div
        key={item.title}
        className={`shell-menu-item cmt-menu ${getMenuClassName(
          item.path ?? ''
        )}`}
        role='button'
        tabIndex={0}
        onClick={handleCMTOnClick}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleCMTOnClick();
          }
        }}
      >
        {item.title}
      </div>
    );
  }
  if (item.title === 'Genesis Standalone') {
    // Check for "Genesis Standalone" item
    return (
      <div
        key={item.title}
        className={`shell-menu-item genesis-standalone-menu ${getMenuClassName(
          item.path ?? ''
        )}`}
        role='button'
        tabIndex={0}
        onClick={handleGenesisStandaloneOnClick}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleGenesisStandaloneOnClick();
          }
        }}
      >
        {item.title}
      </div>
    );
  }
  return (
    <MenuItem key={item.path} className={getMenuClassName(item.path ?? '')}>
      {item.title}
    </MenuItem>
  );
};

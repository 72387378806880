import React, { Suspense } from 'react';

import { Modal as ShellModal } from '@sede-x/shell-ds-react-framework';

import styled from 'styled-components';

const Modal = styled(ShellModal)`
  .shell-modal-container-content {
    min-height: 150px !important;
  }
  .shell-modal-container-body {
    width: 100%;
  }
`;

const SuspenseLoadPage: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const renderFallBack = () => (
    <Modal
      open
      onClose={() => {}}
      loading
      closable={false}
      maskClosable={false}
      width={200}
      centered
    >
      Loading...
    </Modal>
  );

  return <Suspense fallback={renderFallBack()}>{children}</Suspense>;
};

export default SuspenseLoadPage;

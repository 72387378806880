export interface ShiftInfoI {
  id: number;
  name: string;
  startHr: number;
  startMin: number;
  endHr: number;
  endMin: number;
}
export const priortyMapping = {
  1: { id: 1, name: 'High', icon: 'StarSolid' },
  2: { id: 2, name: 'Medium', icon: 'StarHalfSolid' },
  3: { id: 3, name: 'Low', icon: 'Star' },
};

export const shiftListByTeam: ShiftInfoI[][] = [
  [
    {
      id: 10,
      name: 'Day Shift',
      startHr: 7,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    },
    /* {
      id: 11,
      name: 'Day Shift 2',
      startHr: 7,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    }, */
    {
      id: 12,
      name: 'Night Shift',
      startHr: 19,
      startMin: 0,
      endHr: 8,
      endMin: 0,
    },
    /* {
      id: 13,
      name: 'Night Shift 2',
      startHr: 19,
      startMin: 0,
      endHr: 8,
      endMin: 0,
    }, */
  ],
  [{ id: 1, name: 'Day Shift', startHr: 7, startMin: 0, endHr: 20, endMin: 0 }],
  [
    {
      id: 4,
      name: 'Op 1',
      startHr: 6,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    },
    {
      id: 5,
      name: 'Op 2',
      startHr: 6,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    },
    {
      id: 6,
      name: 'Op 3',
      startHr: 6,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    },
    {
      id: 7,
      name: 'Op 4',
      startHr: 6,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    },
    {
      id: 8,
      name: 'Set Up',
      startHr: 6,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    },
    // {
    //   id: 9,
    //   name: 'Weekend',
    //   startHr: 6,
    //   startMin: 0,
    //   endHr: 20,
    //   endMin: 0,
    // },
  ],
  [
    {
      id: 1,
      name: 'Day Shift',
      startHr: 7,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    },
    {
      id: 3,
      name: 'Night Shift',
      startHr: 19,
      startMin: 0,
      endHr: 8,
      endMin: 0,
    },
    {
      id: 16,
      name: 'Analyst',
      startHr: 6,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    },
    // US - 1966904
    {
      id: 17,
      name: 'Early Shift',
      startHr: 7,
      startMin: 0,
      endHr: 15,
      endMin: 0,
    },
    {
      id: 18,
      name: 'Late Shift',
      startHr: 14,
      startMin: 0,
      endHr: 22,
      endMin: 0,
    },
    {
      id: 19,
      name: 'Trading Shift',
      startHr: 7,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    },
  ],
  [
    {
      id: 14,
      name: 'Biomethane',
      startHr: 7,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    },
    {
      id: 15,
      name: 'Power',
      startHr: 7,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    },
    {
      id: 20,
      name: 'Monthly',
      startHr: 7,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    },
    {
      id: 21,
      name: 'Krakow Ops',
      startHr: 7,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    },
    {
      id: 22,
      name: 'EPTB',
      startHr: 7,
      startMin: 0,
      endHr: 20,
      endMin: 0,
    },
  ],
  [{ id: 1, name: 'Day Shift', startHr: 7, startMin: 0, endHr: 20, endMin: 0 }], // added for Power Portfolio team
  [{ id: 1, name: 'Day Shift', startHr: 7, startMin: 0, endHr: 20, endMin: 0 }], // added for Deal Actualisation team
  [{ id: 1, name: 'Day Shift', startHr: 7, startMin: 0, endHr: 20, endMin: 0 }],
  [{ id: 1, name: 'Day Shift', startHr: 7, startMin: 0, endHr: 20, endMin: 0 }],
  [{ id: 1, name: 'Day Shift', startHr: 7, startMin: 0, endHr: 20, endMin: 0 }],
];
export const NIGHT_SHIFT_IDS = ['3', '12', '13'];

export const defaultWholesalePowerCountryList = {
  '4': [
    'belgium',
    'france',
    'germany',
    'italy',
    'netherlands',
    'spain',
    'switzerland',
    'uk',
  ],
  '5': [
    'belgium',
    'france',
    'germany',
    'italy',
    'netherlands',
    'spain',
    'switzerland',
  ],
  '6': [
    'belgium',
    'france',
    'germany',
    'italy',
    'netherlands',
    'spain',
    'switzerland',
    'uk',
  ],
  '7': [
    'belgium',
    'france',
    'germany',
    'italy',
    'netherlands',
    'spain',
    'switzerland',
    'uk',
  ],
  '8': [
    'belgium',
    'france',
    'germany',
    'italy',
    'netherlands',
    'spain',
    'switzerland',
    'uk',
  ],
  '9': [
    'belgium',
    'france',
    'germany',
    'italy',
    'netherlands',
    'spain',
    'switzerland',
    'uk',
  ],
};

export const countryCode = {
  uk: 'UK',
  germany: 'DE',
  netherlands: 'NL',
  'fr & be': 'FB',
  'central east. europe': 'CE',
  denmark: 'DK',
  italy: 'IT',
  ukraine: 'UA',
  mazara: 'MZ',
};

export const TASK_STATUS = {
  REVERT_CANCEL: 0,
  OPEN: 1,
  CLOSE: 2,
  CANCEL: 3,
  CHECKED: 4,
  UNCHECKED: 5,
};

export const TASK_ACTION = {
  CHECKED: 9,
  UNCHECKED: 10,
};
export const TEAMS = {
  GAS_OPS_SHIFT: 1,
  WHOLESALE_GAS_OPS: 2,
  WHOLESALE_POWER_OPS: 3,
  POWER_SHIFT: 4,
};

export const GetAllOperationsTeamAPI = '/api/cop/Shift/GetAllOperationsTeam';

export const UpdateMultipleTaskStatusAPI =
  '/api/cop/Checklist/UpdateMultipleTaskStatus';
export const UpdateTaskStatusAPI = '/api/cop/Checklist/UpdateTaskStatus';

export const GetUsersByTeamAPI = (temamId: number | undefined) =>
  temamId ? `api/cop/shift/GetUsersByTeam?teamId=${temamId}` : '';

export const DeleteTaskWithTaskGroupKeyAPI = () =>
  `/api/cop/Task/DeleteTaskWithTaskGroupKey?localDateTime=${new Date().toISOString()}`;

export const DownloadExcelAPI = '/api/cop/Task/DownloadExcel';
